export const biography = 
{
    "performance": [
        "Currently the Frankel Endowed Chair in Music and Vice-Chair of the Faculty Executive Committee at Scripps College., Dr. Hao Huang has served as a four-time United States Information Agency Artistic Ambassador. He has been warmly acclaimed in eighteen countries in the Middle East, Asia, Africa, Europe, and North America. Winner of the Overman Foundation first prize, the Van Cliburn Piano Award at Interlochen, the Leonard Bernstein Scholarship at Harvard, the Frank Huntington Beebe Grant for European Study, Dr. Huang was selected as the China Institute in America’s New York Solo Debut Artist at Weill Recital Hall in Carnegie Hall. In addition to being a featured soloist at the George Enescu International Music Festival and the Barcelona Cultural Olympiad, the pianist has appeared as concerto soloist with the Timisoara “Banatul” Philharmonic, Sinfonietta Hungarica, Brevard Music Center Orchestra, Music in the Mountains Festival Orchestra, the New Haven Symphony Orchestra and others. He has received performance grants from the National Endowment of the Arts, the New York and Colorado Councils of the Arts and the California Meet the Composer Series. Founder and original executive director of the Animas Music Festival in Durango, Colorado, Dr. Huang is also active as a chamber musician with the Mei Duo and the Gold Coast Trio. He has frequently appeared in broadcasts on television and radio in concert and lecture format in the USA and abroad. Recently, Dr. Huang was honored by an invitation to perform at the Liszt Akadémia/Emlékmuzeum in Budapest.",
        "Holder of degrees from Harvard University, the Juilliard School and the State University of New York at Stony Brook, where he was a Graduate Council Fellow, Hao Huang has studied with Seymour Bernstein, Leon Fleisher, Richard Goode, and Gilbert Kalish. He has been guest artist faculty at the University of California at Davis, San Francisco State University, the Classical Music Festival in Eisenstadt, Austria, the Regional Centre of the Hungarian Academy of Sciences at Veszprém Castle, Hungary and Xiamen University, People’s Republic of China.",
        "In 2008, Dr. Huang was honored to be a Fulbright Scholar in Music and American Studies at Eötvös Lorand University in Budapest, Hungary. He has been interviewed for articles in the Washington Post, Investor’s Business Daily, and NPR’s Morning Edition. Dr. Huang has previously served on the faculties of the School of Music at Converse College and the Hochschule für Musik “Franz Liszt” in Weimar, Germany."
    ],
    "academic": [
        "Dr. Hao Huang is the Director of the 2015-16 Scripps College Humanities Institute, Senior Advisor to the President for International Initiatives and the Bessie and Cecil Frankel Endowed Chair in Music at Scripps College. He was a 2012-13 American Council on Education Fellow-in-residence at Queens College CUNY, and has served as a United States Information Agency Artistic Ambassador on several overseas tours to Europe, Africa and the Middle East. Dr. Huang remains active as a concert pianist in the USA and abroad. His scholarship includes an “Article of the Year Award” in American Music Teacher (MTNA journal) and other published articles in refereed journalsof Great Britain Hungary, Greece, Japan, China and the USA. His work has been featured in the Chronicle of Higher Education, the Washington Post, National Public Radio’s “Morning Edition”, and the Wilson Quarterly.",
        "A four-time recipient of the Mary W. Johnson Faculty Achievement Award for Outstanding Research, Creative Work and/or Performance (2009, 2006, 2004, 1999), Dr. Huang has also been honored multiple times with the Mary W. Johnson Faculty Achievement Award for Outstanding Teaching at Scripps College (2012, 2010, 2000, 1996). His commitment to interdisciplinary curricula is demonstrated by his decade-plus involvement as a lead professor in the Scripps Humanities Core program. In 2012, Huang was chosen as an NEH Teaching Development Fellow, “Bridging Cultures”, one of 9 selected from 218 applications nationally.",
        "Currently appointed to a five-year term on the Fulbright Specialist Program roster, Dr. Huang was a Fulbright Scholar in American Studies and Music at Eötvös Loránd University, Budapest in 2008. Over the summers of 2007, 2008, 2010, 2012 and 2013, he was the initiator/organizer /leader of Scripps College music faculty delegation visits to the College of Arts of Xiamen University, PRC; in 2009, he directed and led the Scripps College Presidential Visit to Xiamen, Minzu and Beijing Universities, PRC. Huang has demonstrated his commitment to promoting globalization of curriculum by producing institutional international faculty exchange.",
        "With regard to collaborative ventures, Dr. Huang proposed and led a Mellon Foundation Local Initiatives Grant for a Claremont Colleges Faculty College of Music; a Multidisciplinary Faculty Study Group on Contemporary Aesthetics; and the Scripps Teaching and Learning Assessment program that was praised by WASC (for student-directed research and innovative methodology). In 2010-11, Dr. Huang was chosen to be a national participant in the Council of Independent Colleges/AALI Senior Leadership Academy. As Director of the Animas Music Festival, the Belle Terre Chamber Players and the Scripps Symposium on Women in Music, Huang was awarded grants from the National Endowment for the Arts, the New York Council on the Arts, and the California Arts Council “Meet the Composer” program, respectively.",
        "Dr. Huang continues to contribute to Scripps College’s initiatives to hire highly qualified faculty of color with concerted efforts regarding minority candidate recruitment, by building an extensive network of resources. He was Chair of the Scripps Dean of Faculty Search Committee and was elected by Scripps College colleagues as the first faculty of color to serve as Chair of the Faculty Executive Committee and as Chair of the Intercollegiate Faculty Council of the Claremont Consortium. Currently serving his sixth year as Chair of the Scripps Music Department, Huang holds a baccalaureate from Harvard (AB), a master’s degree from the Juilliard School (MM), and a doctorate from StonyBrook University SUNY (DMA)."
    ]
};