export const interview = {
    "details": "This is an interview between PEP and Hao Huang. ",
    "source": "http://community.scrippscollege.edu/haohuang/interview/",
    "content": [
        {
            "question": "What made you go into music?",
            "response": "In my case, as in many others, I believe that I did not really make or have a choice: music chose me. I grew up in a tough little racist town; music seemed to be my refuge, where I could express myself without fear of retaliation. Also music affected me emotionally: My mother recalls coming upon me sobbing at the piano at age six or seven, and when asked why I was crying, I answered that the music made me think of death (it was a Mozart sonata slow movement). Music cannot offer a career, only a calling, and some lucky ones go on to practice their art as a profession. I wonder whether there can be a conscious decision to go into music. I think that is a condition of talent and desire A PRIORI. We can only make decisions to devote our lives to an art that speaks to the deepest part of our beings – and to educate ourselves to become true, complete musicians."
        },
        {
            "question": "What do you enjoy the most about making and teaching music?",
            "response": "Without a doubt, it is the opportunity to share my love and knowledge of music, down to the tiniest detail and most deeply felt moment. Performance represents the electric charge of going on stage to find the best in yourself, in fact gives you a chance to be better than you were before, so you can create an ecstatic embrace of music, audience and performer in shared exaltation. Teaching offers the opportunity to build a knowledge of a piece incrementally with someone else, pondering technical problems and solutions and creating musical gestures to help a student find his/her way through the music to speak for him or herself."
        },
        {
            "question": "What do you think is the “best” way to learn to play?",
            "response": "I think that each of us has to find his or her OWN way, both in terms of relationship to the music and technical approach. Of course, there are certain logical principles of effective and economical physical motion at the keyboard. Teaching the right sitting posture, hand position, arm alignment, wrist flexibility, and above all, a relaxed approach to the keyboard is essential from the beginning. But each hand is different, and that results not only in different fingerings for the same passages but also different technical approaches which are appropriate for different individuals. Keen observation and absolute honesty are essential not only to the teacher but to the student, who must become dedicated to be fully aware of what he/she is doing at the piano. I also think that basic musicianship skills, ear training and theory are basic musical necessities. The student has to learn to LISTEN AND THINK! Frankly, the music has to mean something to the child, even at the beginning. Otherwise, we’re just training monkeys for entertainment, not music."
        },
        {
            "question": "What advice would you give to students of the piano/teachers of the piano or music generally?",
            "response": "I think that the most important thing is to LOVE music. Through that, you learn how to care about the work that we do in music and what we are trying to communicate through our music. Students should appreciate the responsibility of sharing the same art as Bach, Beethoven, Chopin, to name but a few. This is a privilege, to recreate masterworks by some of the brightest geniuses that have lit humankind. Every piano student, no matter how humble, partakes of that artistic knowledge of which Raphael wrote, “To understand is to equal”. Every piano teacher is also involved in sharing the understanding of the passion and precision of our sublime enterprise. We’ve got to care enough to explain, demonstrate, and lead by example."
        },
        {
            "question": "What kinds of things would you advise students of the piano and their teachers to try to avoid?",
            "response": "Don’t repeat what you don’t understand. There are many bad habits which start out as innocuous repetitions of something that someone once did, for reasons which are not appropriate now. Students must try to understand WHY they should approach music in the way they are taught; teachers need to understand that knowledge doesn’t stand still, and that they owe it to themselves and their students to constantly reevaluate. Bad practicing often comes from mindless repetition of mistakes, of bad technical approaches. Students must STOP. THINK. IDENTIFY. Then RESOLVE the problem, don’t bang it to death. We’ve got to make sure that we are communicating accurately and effectively."
        },
        {
            "question": "What were your best and worst performances in teaching and performing?",
            "response": "Frankly, I don’t think it’s healthy to dwell on negative experiences. I’ve certainly had my share of humbling experiences, but the worst occurred while I was still a student trying to figure how to connect with the music. You can lose the music but the music won’t lose you, if you’re really part of it. My best performing experience occurred with the Beethoven Fourth Piano Concerto at the George Enescu Festival in Romania. I was petrified backstage, but due to divine grace and inspiration I lost most of myself and self-consciousness. The performance was mainly about Beethoven and his God. What an experience! It was like riding, no, BEING “the wave”, that zone of consciousness that all performers aspire to. My best teaching experience? When I hear a student play better than they ever have, better than they have anticipated, THAT represents a teaching highlight."
        },
        {
            "question": "Who was the most influential person in your years as a student of the piano?",
            "response": "That would be Seymour Bernstein, author of With Your Own Two Hands and composer of many student and artist piano works. Through his caring teaching and his example as a human being, I confirmed in myself a drive to follow my path in music as far as possible. He has uncompromising ideals in the realization of music, which he tempers with sympathy and patience towards his pupils. His attention to the physical details of piano technique as well as musical phrasing and structure are still key to my understanding of music. I should also acknowledge my debts to Leonard Bernstein, whose inspiring charisma in his YOUNG PEOPLE’S CONCERTS showed me that it was possible to be both a performer and a serious intellectual, and who later made it possible for me to pursue that ideal at Harvard University on the L. Bernstein music scholarship. Leon Fleisher, who taught me while I was in college, still represents the acme of teaching musical interpretation and inspiration to me."
        },
        {
            "question": "Do you have a favorite pianist? If so, what attracts you to that person?",
            "response": "Having a healthy ego as a practicing performer, I’d have to say there is no single person I model myself on. I’m attracted by variety and unexpected pleasures: Clifford Curzon in the Liszt Sonata, Fischer in Bach, Schnabel in Schubert. But old favorites still delight me: Rubinstein, Moravec and Lipatti in Chopin, Cortot in Schumann, Horowitz and Ponti in Liszt, Fleisher in ANY concerti — the list goes on and on. Currently, I enjoy the panache of Watts, the sensitivity of Lupu, the touch of Perahia, and the craziness of Cherkassy and Pogorelich."
        },
        {
            "question": "What does it take to be a ‘successful’ musician or music educator?",
            "response": "I don’t really know. I’m still working on it! I hope that the common denominator is LOVE. Love demands responsibility, thoughtfulness, devotion and action. Those are good things to build from."
        },
        {
            "question": "Can you give us your reflections on music as a career?",
            "response": "I will repeat myself: music is NOT a career. This is not a profession you choose for glamour, ego satisfaction or traveling opportunities. You do it almost in spite of yourself, because it is the only thing for you to do. Notice the construction of my sentence: not the only thing you CAN do, the only thing FOR you to do. As a student at Harvard, I tried my best to pick out a sensible career. I failed. What I found was that I could not live with myself before finding out how far I could go in the field which, like the Sirens of ancient Greece, claimed me. I don’t think that I really had a choice whether or not to do this. I just had to try. Then there is the statement which has helped carry me through the tough times: “success in music just depends on sticking around long enough until people get to know you”."
        },
        {
            "question": "What deficiency in training or technique do you most often find in students?",
            "response": "TENSION. Too many talented students are rushed into difficult repertoire without proper technical preparation or guidance. They play with high shoulders, high wrists and tension filled technique. You can’t make the music sound natural with an unnatural physical approach. Also I’d like to echo Dr. Leland’s observation that students have to become more familiar with the language of classical music, certainly before they try to say anything with it! I’m appalled by the lack of listening that infects many musicians – how can we learn to listen to ourselves if we haven’t learned to listen to others? I’m chagrined that many piano students haven’t heard much by Rubinstein, or Schnabel or Cortot. Fischer, Hoffman, Rosenthal, Gieseking are little known by most piano teachers. This is our heritage! How can we keep our music strong and vital if we forget our noble past?"
        },
        {
            "question": "What can we do as musicians to interest more people, children especially?",
            "response": "EXPOSURE. Constant exposure, be it playing, singing, listening – just sharing music. It’s up to the family to instill a cultural value for classical music. It’s certainly better food for the mind than most of the food we put into our bellies! I am mortified that as a classical musician, I am considered somewhat of an antiquarian. That’s not why I love music. It’s about how I am now as a human being, and how I want to share that with as many people as possible who have the capability of appreciating it. I’m resigned to the fact that all art is an elitist activity: it appeals only to the best, brightest, the most imaginative individuals. So we’ll never match the numbers for entertainment. But we must do better to give potential musicians and audience members a chance to participate in our music, by making it in as many places and presenting it in as many venues as possible. That’s why I am so excited about The Piano Education Page."
        },
        {
            "question": "What are your views on competitions?",
            "response": "I concur with the majority that they are necessary evils, We are too commercially constituted as a society to banish them. Speaking from the perspective of a judge at an international piano competition in Europe, I found that it was far safer to award the reliable performer, not to reward the creative musician. My one caveat is the old saw, “there is nothing sadder than last year’s competition winner”. We must resist treating musicians as commodities, to be disposed of or traded in once they lose the freshness date. I am alarmed by the economic pressure which dictates that classical CD sales be boosted by promoting the next prepubescent superstar. The newest and the latest is not always the best, in our art form. It is healthy to provide alternatives to the competition route. Yet, I encourage my students to enter appropriate auditions because 1) competition is inevitable and unavoidable in any field 2) It is good to have a goal to prepare for and to get an idea of what other people are doing. We just can’t take competitions too seriously. Winning one doesn’t make a career, let alone a life."
        },
        {
            "question": "Pretend this is your personal soapbox. What would you like to say?",
            "response": "I’d like to return to the theme of love and care for music, which is evanescent yet transcendent, binding musicians of the present with the greats of the past, students to teachers, ourselves to each other. Musical performance ends with the last vibration, yet we as musicians carry the promise of music within ourselves. Music goes beyond words, beyond thoughts, beyond feelings, even beyond our individual selves. We have to champion the necessity of that kind of aesthetic experience, which affects the quality of life. Given the state of our world, how can anyone say that beauty is a luxury? And as Plato says, “The man who is in love with music is in love with the loveliest”."
        },
    ]
};